
import { defineComponent, reactive, toRefs, computed } from 'vue';
import PlanInputCheckbox from '@/components/forms/PlanInputCheckbox.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import { locationString, locationObject } from '@/utils/formatLocation';
import StopData from '@/modules/planning/newLoad/_components/form/StopData.vue';

export default defineComponent({
  name: 'PlanUpdateMultipleStopLocation',
  components: { PlanInputCheckbox, StopData },
  emits: ['change'],
  props: {
    type: { type: String, required: true, default: 'pickup' },
  },
  setup(props, { emit }) {
    const store = useStore();
    const translate = useI18n();

    const data = reactive({
      showForm: false,
      commodity: '',
      locationObject: {} as any,
      place: '',
      showTime: true,
    });

    const change = () => {
      emit('change', {
        isActive: data.showForm,
        locationObject: data.locationObject,
        place: data.place,
      });
    };

    const changeShowForm = (value: boolean) => {
      data.showForm = value;
      change();
    };

    const portOrAirport = (pick, stop) => {
      if (pick === 'port') {
        stop.airportStop = false;
        stop.portStop = !stop.portStop;
      }
      if (pick === 'airport') {
        stop.portStop = false;
        stop.airportStop = !stop.airportStop;
      }
    };
    const setStopLocation = (stop, addressData) => {
      stop.location = locationObject(addressData);
    };
    const updateHour = (item) => {
      if (item.executionWindowStartTime > item.executionWindowEndTime) {
        item.executionWindowEndTime = item.executionWindowStartTime;
        data.showTime = false;
        setTimeout(() => {
          data.showTime = true;
        }, 1);
      }
    };
    const updateLumperFee = (stop, isLumperFee, price) => {
      if (isLumperFee) {
        stop.lumperFeePrice = price;
      } else {
        stop.lumperFeePrice = null;
      }
    };
    const updateObservations = (event, stop) => {
      stop.observations = event.target.innerText;
    };
    const updatePoNumber = (event, stop) => {
      stop.poNumber = event.target.innerText;
    };
    const updateWarehouse = (
      stop,
      address,
      companyName,
      lumperFee,
      lumperFeePrice,
      observations,
      poNumber,
      phoneNumber,
      phonePrefix,
      contactName,
      warehouseId,
      executionWindowStartTime,
      executionWindowEndTime,
    ) => {
      stop.companyName = companyName;
      stop.location.address = address;
      stop.lumperFee = lumperFee;
      stop.lumperFeePrice = lumperFeePrice;
      stop.observations = observations;
      stop.poNumber = poNumber;
      stop.phoneNumber = phoneNumber;
      stop.phonePrefix = phonePrefix;
      stop.contact = contactName;
      stop.executionWindowStartTime = executionWindowStartTime;
      stop.executionWindowEndTime = executionWindowEndTime;
      warehouseId !== null
        ? (stop.warehouseTemplate = { id: warehouseId })
        : (stop.warehouseTemplate = null);
    };
    const countrySelected = (country, stop) => {
      stop.defaultCountrySelected = country;
    };

    const getLocationData = (stop: any, addressData) => {
      store
        .dispatch('LoadsStore/getPlaceDetailsGoogle', { placeId: addressData.placeId })
        .then((response) => {
          if (!stop.location) {
            stop.location = {
              googlePlaceId: '',
              lat: 0,
              long: 0,
              zip: '',
              city: '-',
              state: {
                iso: '-',
                country: {
                  iso: '-',
                },
                isoGoogle: '-',
              },
            };
          }

          if (response && response.data) {
            data.place = response.data.formatted_address;
            stop.location.googlePlaceId = response?.data?.place_id;
            stop.location.lat = response.data.geometry.location.lat;
            stop.location.long = response.data.geometry.location.lng;
            response.data.address_components.forEach((addressComponent: any) => {
              if (addressComponent.types.includes('postal_code'))
                stop.location.zip = addressComponent['short_name'];
              if (addressComponent.types.includes('country'))
                stop.location.state.country.iso = addressComponent['short_name'];
              if (addressComponent.types.includes('administrative_area_level_1')) {
                stop.location.city = addressComponent['long_name'];
                stop.location.state.iso = addressComponent['short_name'];
                stop.location.state.isoGoogle = addressComponent['short_name'];
              }
              if (
                addressComponent.types.includes('administrative_area_level_2')
                && stop.location.city === '-' && stop.location.state.iso === '-'
                && stop.location.state.isoGoogle === '-'
              ) {
                stop.location.city = addressComponent['long_name'];
                stop.location.state.iso = addressComponent['short_name'];
                stop.location.state.isoGoogle = addressComponent['short_name'];
              }
            });
          }

          change();
        });
    };

    const title = computed(() => {
      let result = '';
      result += props.type === 'pickup' ? translate.t('pick-up') : translate.t('delivery');
      return result;
    });

    return {
      ...toRefs(data),
      change,
      changeShowForm,
      portOrAirport,
      setStopLocation,
      updateHour,
      updateLumperFee,
      updateObservations,
      updatePoNumber,
      updateWarehouse,
      countrySelected,
      getLocationData,
      title,
    };
  },
});
